.client-page {
    .title-page {
        background: url(../../../assets/images/advanced-cyber-security-header-02.jpg) no-repeat center;
        background-size: 100% auto;
        @media only screen and (max-width: 960px) {
            background-size: 100% 100%;
        }
        .content-title-page {
            padding: 60px 0;
            @media only screen and (max-width: 960px) {
                padding: 30px 0;
            }
            h3 {
                color: #fff;
                @media only screen and (max-width: 960px) {
                    font-size: 2rem;
                }
            }
        }
    }
}
