// $primary: #03a89d;
// $primary-light: #16e7cf;
// $secondary: #ff644e;
// $primary-dark: #03a89d;
$bg-dark: #17222d;
$bg-light: #37c0ca;
// $bg-light-03: #293646d0;
// $bg-light-01: #29364650;
// $yellow: #efb909;
// $yellow-dark: #eeae00;
// $dark-white: rgba(255, 255, 255, 0.8);
$color-primary: #37c0ca;
$blue-color: #005c99;
$color-dark-blue: #17222d;
$color-dark: #131415;
$color-white: #fff;
$color-black: #000;
