html {
  &.client-page {
    .blog {
      padding: 2rem 0rem;
      @media screen and(min-width:600px) {
        padding: 5rem 0rem;
      }
      text-align: center;
      h6 {
        text-align: center;
        text-transform: capitalize;
        color: #37c0ca;
        margin-bottom: 3rem;
        @media screen and(min-width:600px) {
          margin-bottom: 6rem;
        }
      }
      .box-card {
        text-align: left;
        margin-bottom: 2rem;
        @media screen and(min-width:600px) {
          margin-bottom: 6rem;
        }
        &:hover {
          .title {
            color: #37c0ca;
          }
        }
        a {
          cursor: pointer;
          img {
            width: 100%;
            object-fit: cover;
          }
          span {
            display: inline-block;
            margin: 1rem 0rem;
            font-weight: 500;
            font-size: 1.2rem;
            color: #929292;
          }
          .title {
            margin-bottom: 0.5rem;
            color: #656b6f;
          }
        }
        .card-info {
          // height: 10rem;
          color: #929292;
        }
      }
      .btn-more {
        background: #37c0ca;
        padding: 1.5rem 2.5rem;
        border-radius: 50rem;
        color: #fff;
        font-size: 1.5rem;
        margin-top: 3rem;
        transition: all 0.3s ease;
        &:hover {
          box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
