html {
  &.client-page {
    @import "../../scss/default/color.scss";
    .sidebar {
      display: none;
      background: $bg-dark;
      padding: 6.5px 0rem;
      border-bottom: 3px solid #37c0ca;
      .logo {
        img {
          width: 180px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button {
          border: none;
          background: transparent;
          svg {
            color: #fff;
          }
        }
      }
    }
    @media screen and(max-width:960px) {
      .sidebar {
        display: block;
      }
    }
  }
}
