@import "./color.scss";

button:focus {
  outline: none;
}

.text-primary {
  color: $color-primary !important;
}
.bg-primary {
  background-color: $color-primary !important;
}
.opacity-06 {
  opacity: 0.6;
}
.h-100pc {
  height: 100%;
}
.w-100pc {
  width: 100% !important;
}
.text-bolder {
  font-weight: bolder !important;
}
.carousel-control,
.carousel-control-prev,
.carousel-control-next {
  width: auto;
}
a {
  text-decoration: none !important;
  color: $color-primary;
  opacity: 0.9;
  &:hover {
    color: $color-primary;
    opacity: 1;
  }
}
.MuiButton-containedSecondary {
  color: #fff !important;
}
// .MuiPaper-outlined {
//   border: 1px solid rgba(0, 0, 0, 0.09) !important;
//   border-radius: 10px !important;
//   &.active {
//     border: 4px solid rgba($color: $color-primary, $alpha: 1) !important;
//   }
//   &.input-border {
//     border: 1px solid rgba(0, 0, 0, 0.23) !important;
//   }
// }
// .MuiCircularProgress-colorPrimary {
//   color: $color-primary !important;
//   * {
//     color: currentColor !important;
//   }
// }

.MuiButton-root[disabled] {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.dashboard-page title-page:before,
.dashboard-page title-page:after {
  background-color: $color-black !important;
}

.filter-bar {
  box-shadow: none;
  label {
    margin-bottom: 0;
    opacity: 0.8;
  }
  .MuiFilledInput-root {
    border-radius: 0;
    &:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
  }
  .MuiInputBase-input {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .MuiSelect-filled.MuiSelect-filled {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    text-align: center;
    opacity: 0.8;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline::after {
    display: none !important;
  }
}
.show-lg,
.show-xs,
.show-md {
  display: none !important;
}
.menu-icon {
  height: 30px;
  width: 30px;
}
.MuiButton-root {
  border-radius: 50px !important;
  &.no-min-width {
    min-width: auto !important;
  }
  &.no-border-radius {
    border-radius: 4px !important;
  }
}
.count-down {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 20px;
  color: $color-primary !important;
}

.upload-photo {
  border: 1px solid;
  border-radius: 8px;
  height: 100%;
  position: relative;
  padding-top: 25px;
  padding-bottom: 50px;
  label.upload {
    height: 100%;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    position: relative;
    min-height: 100px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .link {
      position: absolute;
      width: 100%;
      top: 50%; /* position the top  edge of the element at the middle of the parent */
      left: 50%; /* position the left edge of the element at the middle of the parent */

      transform: translate(-50%, -50%);
    }
  }
  .title {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.add-position.MuiButton-root {
  min-width: auto !important;
}
@media screen and(max-width:1208px) {
  .hide-lg {
    display: none !important;
  }
  .show-lg {
    display: block !important;
  }
}
@media screen and(max-width:960px) {
  .hide-md {
    display: none !important;
  }
  .show-md {
    display: block !important;
  }
}
@media screen and(max-width:600px) {
  .count-down {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 17px;
  }
  .hide-xs {
    display: none !important;
  }
  .show-xs {
    display: block !important;
    &.MuiTableRow-root {
      display: table-row !important;
    }
  }
  .dashboard-page .binary-tree form.MuiPaper-root input {
    width: 100% !important;
  }
  .MuiDrawer-root.custom-modal-vk {
    .MuiDivider-root {
      width: auto;
    }
  }
}

#fb-root {
  .fb_dialog_content {
    iframe {
      bottom: 125px !important;
    }
  }
}
