.page404 {
    height: calc(100% - 64px);
    position: relative;
    padding: 5em 0 !important;
    .img404 {
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 0;
    }
    .content {
        text-align: center;
        position: relative;
        z-index: 1;
        h1 {
            font-size: 200px;
            margin: 0;
        }
        p {
            font-size: 20px;
            margin: 0;
        }
    }
}
