.dashboard-page {
  .home {
    .carousel {
      .content-slide {
        // height: 200px;
        @media screen and(min-width:600px) {
          // height: 250px;
        }
        @media screen and(min-width:960px) {
          // height: 300px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .transaction-home-page {
      .transaction-item {
        width: 25%;
      }
    }

    .wallet-home-page {
      .wallet-item {
        width: 33.3333%;
      }
    }

    .menu-home-page {
      .menu-item {
        width: 25%;
      }
    }

    .home-page {
      margin-top: -1rem;
    }
  }
}
