.custom-modal-vk {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  overflow-x: hidden !important;
}

.custom-modal-vk > div:nth-child(3) {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  background: transparent;
  display: grid;
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  margin: auto;
  flex: unset;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(107, 103, 103, 0.5);
}

.dashboard-page {
  .risk-warning {
    position: relative;
    .icon-down-up {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      text-align: center;
      cursor: pointer;
    }
  }
}
