html {
  &.client-page {
    .partner {
      background-color: #000;
      padding: 3rem 0rem;
      @media screen and(min-width:600px) {
        padding: 6rem 0rem;
      }
      .title {
        text-align: center;
        text-transform: capitalize;
        margin-bottom: 6rem;
        color: #37c0ca;
        @media screen and(min-width:600px) {
          margin-bottom: 12rem;
        }
      }
      .partner-desktop {
        display: block;
        .list-card {
          display: flex;
          align-items: center;
          justify-content: center;
          .card-item {
            clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
            width: 100px;
            height: 115px;
            margin: 0 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease;
            @media screen and(min-width:960px) {
              margin: 0 7px;
              width: 160px;
              height: 185px;
            }
            @media screen and(min-width:1280px) {
              margin: 0 10px;
              width: 200px;
              height: 231px;
            }
            &:hover {
              transform: scale(1.08);
            }
            a {
              text-align: center;
              img {
                max-width: 85px;
                max-height: 55px;
                @media screen and(min-width:960px) {
                  max-width: 125px;
                  max-height: 90px;
                }
                @media screen and(min-width:1280px) {
                  max-width: 150px;
                  max-height: 120px;
                }
              }
            }
          }
        }
        .list-card-top {
          .card-item {
            &:nth-child(1) {
              background: #40c3cb;
            }
            &:nth-child(2) {
              background: linear-gradient(104.24deg, rgb(26, 116, 252) -4.4%, rgb(78, 242, 134) 112.23%);
            }
            &:nth-child(3) {
              background: #1a4fc9;
            }
            &:nth-child(4) {
              background: #041727;
            }
          }
        }
        .list-card-bottom {
          margin-top: -20px;
          @media screen and(min-width:960px) {
            margin-top: -35px;
          }
          @media screen and(min-width:1280px) {
            margin-top: -41px;
          }
          .card-item {
            &:nth-child(1) {
              background: #363d47;
            }
            &:nth-child(2) {
              background: #e4e4e4;
            }
            &:nth-child(3) {
              background: #12161c;
            }
            &:nth-child(4) {
              background: #004d7f;
            }
            &:nth-child(5) {
              background: #cecdcd;
            }
          }
        }
      }
      .partner-mobile {
        .list-card {
          display: flex;
          align-items: center;
          justify-content: center;
          .card-item {
            clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
            width: 100px;
            height: 115px;
            margin: 0rem 0.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
              text-align: center;
              img {
                max-width: 70px;
                max-height: 65px;
              }
            }
          }
        }
        .list-card-slot-2,
        .list-card-slot-3,
        .list-card-slot-4,
        .list-card-slot-5 {
          margin-top: -25px;
        }
        .list-card-slot-1 {
          .card-item {
            &:nth-child(1) {
              background: #40c3cb;
            }
            &:nth-child(2) {
              background: linear-gradient(104.24deg, rgb(26, 116, 252) -4.4%, rgb(78, 242, 134) 112.23%);
            }
            &:nth-child(3) {
              background: #1a4fc9;
            }
          }
        }
        .list-card-slot-2 {
          .card-item {
            &:nth-child(1) {
              background: #041727;
            }
            &:nth-child(2) {
              background: #363d47;
            }
          }
        }
        .list-card-slot-3 {
          .card-item {
            &:nth-child(1) {
              background: #e4e4e4;
            }
            &:nth-child(2) {
              background: #12161c;
            }
            &:nth-child(3) {
              background: #004d7f;
            }
          }
        }
      }
    }
  }
}
