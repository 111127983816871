.dashboard-page {
  overflow-x: hidden;
  .btn:active,
  .btn:focus,
  [type="button"] {
    outline: none !important;
    box-shadow: none;
  }

  .title-page {
    position: relative;
    text-align: center;
    margin-bottom: 1em;
    padding-bottom: 0.3em;
    &:after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 50%;
      height: 1px;
      width: 200px;
      background-color: #28aaffa6;
      transform: translateX(-50%);
    }
    &:before {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 50%;
      height: 3px;
      width: 50px;
      background-color: #28aaff;
      transform: translateX(-50%);
    }
  }
  .endAdornment-inputText {
    white-space: nowrap;
    cursor: pointer;
    position: relative;
  }
  .hover:hover {
    opacity: 0.7;
  }
  .disabledEvent {
    pointer-events: none;
  }
  .swal2-container {
    z-index: 1500;
  }
  .header-paper {
    padding: 0.3em 0.6em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  .modal-custom {
    width: 100%;
    @media screen and(min-width:600px) {
      min-width: 550px;
      max-width: 550px;
    }
  }
  .MuiAppBar-colorPrimary {
    border-bottom: 3px solid #131415;
    &.footer {
      border-bottom: none;
      border-top: 3px solid #131415;
    }
  }

  .left-menu-db {
    .icon-open-closed {
      position: absolute;
      right: -5px;
      top: 5px;
      border-radius: 10px 0px 0px 10px;
      padding: 7px 5px 7px 10px;
    }
  }

  .mode-template {
    svg {
      font-size: 1em;
    }
  }

  .social-db {
    position: fixed;
    bottom: 0;
    padding: 10px 0;
    ul {
      padding: 0;
      text-align: center;
      margin: 0;
      li {
        list-style: none;
        display: inline-block;
        margin-left: 0.5em;
        margin-right: 0.5em;
        img {
          height: 35px;
        }
      }
    }
  }

  .icon-app {
    height: 60px;
  }

  .footer {
    position: fixed;
    top: auto;
    bottom: 0;
  }
}
