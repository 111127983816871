html {
  &.client-page {
    .media {
      background-image: linear-gradient(to right, #0076ba, #16e7cf);
      padding: 1rem 0rem;
      @media screen and(min-width:600px) {
        padding: 3rem 0rem;
      }
      @media screen and(min-width:960px) {
        padding: 6rem 0rem;
      }
      .title {
        color: #fff;
        text-align: center;
        text-transform: capitalize;
      }
      .list-card {
        .card-item {
          a {
            .box-card {
              background: rgba(255, 255, 255, 0.5);
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.5s ease;
              padding: 1.5em 1em;
              @media screen and(min-width:600px) {
                padding: 2em 1em;
              }
              @media screen and(min-width:960px) {
                padding: 3em 1em;
              }
              &:hover {
                transform: scale(1.02);
              }
              img {
                height: 20px;
                max-width: 100%;
                @media screen and(min-width:600px) {
                  height: 28px;
                }
                @media screen and(min-width:960px) {
                  height: 35px;
                }
              }
            }
          }
        }
      }
    }
  }
}
