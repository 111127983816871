.dashboard-page {
  .pricing-design {
    text-align: center;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease 0s;
    &.blue {
      border: 1px solid #005c99;
    }

    &.red {
      border: 1px solid #db2c29;
    }

    &:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
      transform: scale(1.02);
      z-index: 1;
    }

    svg {
      display: block;
      margin-left: -1px;
    }

    .pricing-content {
      padding: 20px 10px;
      position: relative;
    }

    .title {
      font-size: 35px;
      font-weight: 600;
      color: #ae003d;
      text-transform: uppercase;
      margin: 0 0 10px;
    }

    .pricing-content ul {
      padding: 0;
      margin: 0 0 30px;
      list-style: none;

      li {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.3);
        line-height: 40px;
        text-transform: capitalize;
      }
    }

    .pricingTable-signup {
      display: inline-block;
      padding: 8px 10px;
      background: #ae003d;
      border-radius: 20px;
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      position: relative;
      transition: all 0.3s ease 0s;
      text-decoration: none;
    }

    &:hover .pricingTable-signup {
      box-shadow: 0 0 10px #ae003d;
    }

    .pricingTable-signup:hover {
      box-shadow: 0 0 10px #ae003d, 0 0 10px rgba(0, 0, 0, 0.5) inset;
      text-decoration: none;
    }

    &.blue {
      .title {
        color: #005c99;
      }

      .pricingTable-signup {
        background: #005c99;
      }

      &:hover .pricingTable-signup {
        box-shadow: 0 0 10px #005c99;
      }

      .pricingTable-signup:hover {
        box-shadow: 0 0 10px #005c99, 0 0 10px rgba(0, 0, 0, 0.5) inset;
      }
    }

    &.red {
      .title {
        color: #db2c29;
      }

      .pricingTable-signup {
        background: #db2c29;
      }

      &:hover .pricingTable-signup {
        box-shadow: 0 0 10px #db2c29;
      }

      .pricingTable-signup:hover {
        box-shadow: 0 0 10px #db2c29, 0 0 10px rgba(0, 0, 0, 0.5) inset;
      }
    }
  }
}
