$bg-transparent: transparent;

$modes: (
  dark: "dark",
  light: "light",
  purple: "purple",
  orange: "orange",
);

@each $mode, $value in $modes {
  $bg-color: #131415;
  $box-color: #19222c;
  $text-color: #fff;
  $primary-color: #37c0ca;
  $error-color: #f44336;

  @if ($value == "dark") {
    $bg-color: #131415;
    $box-color: #19222c;
    $text-color: #fff;
    $primary-color: #37c0ca;
    $error-color: #f44336;
  } @else if($value == "purple") {
    $bg-color: #ddd;
    $box-color: #593196;
    $text-color: #fff;
    $primary-color: #37c0ca;
    $error-color: #f44336;
  } @else if($value == "orange") {
    $bg-color: #ddd;
    $box-color: #e95420;
    $text-color: #000;
    $primary-color: #37c0ca;
    $error-color: #f44336;
  } @else {
    $bg-color: #f0f2f4;
    $box-color: #fff;
    $text-color: #000;
    $primary-color: #37c0ca;
    $error-color: #f44336;
  }

  .dashboard-page {
    .#{$mode}-mode {
      color: rgba($color: $text-color, $alpha: 0.7);
      background-color: $bg-color;

      .card,
      .btn,
      .list-group-item,
      .MuiPaper-root,
      .MuiTab-root,
      .MuiButton-root,
      .MuiTablePagination-root,
      .MuiSvgIcon-colorAction,
      .MuiSelect-icon,
      .MuiTableCell-body,
      .MuiTableCell-head,
      .MuiInput-root,
      .MuiIconButton-root,
      .MuiInputBase-root,
      .MuiTypography-colorTextSecondary,
      .MuiFormHelperText-root,
      .MuiFormLabel-root {
        color: rgba($color: $text-color, $alpha: 0.7);
      }

      .card,
      .bg-box,
      .list-group-item,
      .pricing-design,
      .MuiPaper-root,
      .MuiTablePagination-root,
      .MuiSvgIcon-colorAction,
      .MuiSelect-icon,
      .MuiTableCell-body,
      .MuiTableCell-head,
      .MuiIconButton-root,
      .MuiFormLabel-root {
        background-color: $box-color;
      }

      .title-page {
        &:before,
        &:after {
          background-color: $text-color;
        }
      }

      .MuiTableCell-head {
        color: $text-color;
      }

      .MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
          border-color: rgba($color: $text-color, $alpha: 0.1);
        }
        &.Mui-focused,
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: rgba($color: $text-color, $alpha: 0.6);
          }
        }
      }

      .upload-photo {
        border-color: rgba($color: $text-color, $alpha: 0.1);
        &:hover {
          border-color: rgba($color: $text-color, $alpha: 0.6);
        }

        &.error {
          border-color: $error-color;
        }
      }

      .MuiInput-root,
      .MuiInputBase-root,
      .MuiIconButton-root {
        background-color: $bg-transparent;
      }

      .MuiButton-outlined {
        border-color: rgba($color: $text-color, $alpha: 0.7);
      }

      .pincode-input-container {
        .pincode-input-text {
          border-color: rgba($color: $text-color, $alpha: 0.7) !important;
          color: rgba($color: $text-color, $alpha: 0.7) !important;
          background-color: $bg-transparent;
        }
      }

      .list-group-item,
      .card-header,
      .MuiTableCell-root {
        border-bottom: 1px solid rgba($color: $text-color, $alpha: 0.2);
      }

      .MuiInput-underline {
        &:before,
        &:after {
          border-bottom: 1px solid rgba($color: $text-color, $alpha: 0.1);
        }
        &:hover:not(.Mui-disabled) {
          &:before {
            border-bottom: 2px solid rgba($color: $text-color, $alpha: 0.2);
          }
        }
      }

      .MuiSvgIcon-colorDisabled,
      .Mui-disabled {
        color: rgba($color: $text-color, $alpha: 0.4);
      }

      .MuiDivider-root {
        background-color: rgba($color: $text-color, $alpha: 0.1);
      }

      .MuiLinearProgress-root {
        background-color: $bg-color;
      }

      /*#region border-color */
      .border {
        border: 1px solid rgba($color: $text-color, $alpha: 0.3) !important;
      }

      .border-top {
        border-top: 1px solid rgba($color: $text-color, $alpha: 0.3) !important;
      }

      .border-right {
        border-right: 1px solid rgba($color: $text-color, $alpha: 0.3) !important;
      }

      .border-bottom {
        border-bottom: 1px solid rgba($color: $text-color, $alpha: 0.3) !important;
      }

      .border-left {
        border-left: 1px solid rgba($color: $text-color, $alpha: 0.3) !important;
      }
      /*#endregion */

      /*#region error-color */
      .MuiFormHelperText-root,
      .MuiFormLabel-asterisk {
        &.Mui-error {
          color: $error-color;
        }
      }
      .MuiInput-underline {
        &.Mui-error {
          &:after {
            border-bottom-color: $error-color;
          }
        }
      }
      /*#endregion */

      /*#region primary-color */
      .MuiSvgIcon-colorPrimary,
      .MuiTypography-colorPrimary,
      .MuiButton-outlinedPrimary,
      .MuiCircularProgress-colorPrimary,
      .MuiChip-colorPrimary,
      .MuiCheckbox-colorPrimary {
        color: $primary-color;
      }

      .MuiTabs-indicator,
      .MuiLinearProgress-barColorPrimary,
      .MuiButton-containedPrimary {
        background-color: $primary-color;
      }

      .MuiAppBar-colorPrimary {
        border-bottom-color: $primary-color;
        &.footer {
          border-top-color: $primary-color;
        }
      }

      .MuiButton-outlinedPrimary {
        border-color: rgba($color: $primary-color, $alpha: 0.5);
        &:hover {
          border-color: rgba($color: $primary-color, $alpha: 1);
        }
      }

      .MuiTab-textColorPrimary {
        &.Mui-selected {
          color: $primary-color;
        }
      }

      .MuiPaper-outlined,
      .MuiChip-colorPrimary {
        border-color: rgba($color: $primary-color, $alpha: 0.5);
      }

      .mode-template {
        svg {
          color: $primary-color;
        }
      }
      /*#endregion */

      /*#region tree*/
      .binary-tree {
        .tree {
          ul,
          li,
          .content-tree {
            &:before {
              outline: 1px solid rgba($color: $text-color, $alpha: 0.1);
            }
          }
          .content-tree {
            border: 1px solid rgba($color: $text-color, $alpha: 0.1);
            background-color: $box-color;
            &.btn-add-tree {
              .btn-add-sum {
                background-color: $box-color;
              }
            }
            .sum-credits {
              border-bottom: 1px solid rgba($color: $text-color, $alpha: 0.1);
            }
          }
        }
      }
      /*#endregion */

      /*#region toast*/
      .Toastify__toast-container,
      .Toastify__close-button {
        color: rgba($color: $text-color, $alpha: 0.7);
      }

      .Toastify__toast--default,
      .Toastify__toast--error,
      .Toastify__toast--success {
        background-color: $box-color;
        border: 1px solid rgba($color: $text-color, $alpha: 0.6);
      }

      .Toastify__toast--error {
        .Toastify__progress-bar {
          background: #f50057;
        }
      }
      .Toastify__toast--success {
        .Toastify__progress-bar {
          background: $primary-color;
        }
      }
      /*#endregion */

      /*#region modal */
      .custom-modal-vk {
        > div:nth-child(3) {
          background-color: $box-color;
          padding-bottom: 20px;
          padding-top: 20px;
          height: auto !important;
          box-shadow: 0 0 10px rgba(0, 0, 0, 50%);
          color: rgba($color: $text-color, $alpha: 0.7);
          overflow: hidden;
        }
        .MuiDivider-root {
          background-color: rgba($color: $text-color, $alpha: 0.1);
        }
        .MuiSelect-icon {
          fill: rgba($color: $text-color, $alpha: 0.7);
        }
        .MuiSelect-selectMenu,
        .MuiInputLabel-outlined,
        .MuiSelect-outlined.MuiSelect-outlined {
          color: rgba($color: $text-color, $alpha: 0.7);
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: rgba($color: $text-color, $alpha: 0.7);
        }
      }
      /*#endregion */

      /*#region left menu db*/
      .left-menu-db {
        a {
          color: rgba($color: $text-color, $alpha: 0.7);
          &.active {
            background-color: rgba($color: $text-color, $alpha: 0.1);
          }
        }
        .icon-open-closed {
          background-color: rgba($color: $bg-color, $alpha: 0.9);
        }
      }
      /*#endregion */
    }
  }
}
