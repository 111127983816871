#swap {
  .swap-tabs {
    .MuiTabs-flexContainer {
      border-bottom: 2px solid rgba(255, 255, 255, 0.09);
    }
  }
  img {
    height: 40px;
  }
  .MuiTypography-colorTextSecondary {
    color: #fff;
  }
  .USDT {
    .MuiPaper-outlined {
      background: linear-gradient(to left, #54ac94, #7ac4af);
      background-color: unset !important;
      box-shadow: none;
      border: none !important;
      img {
        background-color: #54ac94;
        border-radius: 50%;
        padding: 5px;
      }
    }
  }
  .FIL {
    .MuiPaper-outlined {
      background: linear-gradient(to left, #0494fc, #61bafc);
      background-color: unset !important;
      box-shadow: none;
      border: none !important;
      img {
        background-color: #0494fc;
        border-radius: 50%;
        padding: 5px;
      }
    }
  }
  .submit-btn {
    width: 80px;
    height: 80px;
    border-radius: 50% !important;
    min-width: unset !important;
  }
}
