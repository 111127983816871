p {
  margin: 0;
}
a {
  text-decoration: none;
  cursor: pointer;
}
li {
  list-style-type: none;
}
