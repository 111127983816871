html {
  &.client-page {
    .footer {
      background: #081726;
      padding: 3rem 0rem 1rem 0rem;
      color: #929292;
      text-align: center;
      @media screen and(min-width:600px) {
        padding: 4rem 0rem 2rem 0rem;
      }
      @media screen and(min-width:960px) {
        text-align: left;
        padding: 6rem 0rem 2rem 0rem;
      }
      .slot-1 {
        @media screen and(max-width:960px) {
          text-align: center;
        }
        img {
          width: 180px;
        }
        span {
          position: absolute;
          top: 50%;
          left: 16.5%;
          font-size: 12px;
          @media screen and(max-width:1280px) {
            top: 50%;
            left: 28%;
          }
          @media screen and(max-width:960px) {
            top: 45%;
            left: 45%;
          }
          @media screen and(max-width:600px) {
            top: 70%;
            left: 47%;
          }
        }
        .content-logo {
          position: relative;
          display: inline-block;
          .email-logo {
            position: absolute;
            top: 40px;
            left: 55px;
          }
        }
      }
      .slot-3,
      .slot-4 {
        label {
          color: #37c0ca;
        }
        a {
          cursor: pointer;
          p {
            font-weight: 300;
            line-height: 2.5rem;
            @media screen and(max-width:960px) {
              background: rgba(255, 255, 255, 0.1);
              margin: 1rem 0.5rem;
              padding: 1rem 0rem;
            }
          }
        }
      }
      .slot-2 {
        @media screen and(max-width:960px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        @media screen and(max-width:600px) {
          margin: 2rem 0rem;
        }
        label {
          color: #37c0ca;
        }
        p {
          font-size: 1.3rem;
        }
        ul {
          display: flex;
          @media screen and(max-width:960px) {
            margin-bottom: 2rem;
          }
          li {
            // margin: 1rem 1rem 0rem 1rem;
            margin-top: 10px;
            a {
              cursor: pointer;
              width: 4rem;
              height: 4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50rem;
              @media screen and(max-width:1280px) {
                width: 3rem;
                height: 3rem;
              }
              img {
                width: 100%;
              }
            }
          }
          & li:nth-child(1) {
            margin-left: 0;
          }
        }
      }
      a {
        color: #929292;
      }
      hr {
        margin-bottom: 2em;
        height: 0.1rem;
        background: #274c65;
        border: none;
      }
      .MuiContainer-root {
        > p {
          font-size: 0.6rem;
          text-align: center;
          color: #274c65;
        }
      }
    }
  }
}
