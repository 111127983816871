.dashboard-page {
  .member {
    .pr-20 {
      padding-right: 20px;
    }
    .pl-20 {
      padding-left: 10px;
    }
    .pt-20 {
      padding-top: 20px;
    }
    .p-5 {
      padding: 5px !important;
    }
    .p-20 {
      padding-bottom: 20px;
    }
    // form.MuiPaper-root {
    //   width: 250px;
    //   input {
    //     width: 190px;
    //     padding-left: 10px;
    //   }
    // }
  }
}
