//===font-size===
//text-main
.txt-main-1 {
  font-size: 120px;
}
.txt-main-2 {
  font-size: 100px;
}
.txt-main-3 {
  font-size: 80px;
}
.txt-main-4 {
  font-size: 60px;
}
.txt-main-5 {
  font-size: 40px;
}
.txt-main-6 {
  font-size: 20px;
}
//text-sub
.txt-sub-1 {
  font-size: 19px;
}
.txt-sub-2 {
  font-size: 18px;
}
.txt-sub-3 {
  font-size: 17px;
}
.txt-sub-4 {
  font-size: 16px;
}
.txt-sub-5 {
  font-size: 15px;
}
.txt-sub-6 {
  font-size: 14px;
}
.txt-sub-7 {
  font-size: 13px;
}
.txt-sub-8 {
  font-size: 12px;
}
.txt-sub-9 {
  font-size: 11px;
}
//===font-weight===
.fw-1 {
  font-weight: 100;
}
.fw-2 {
  font-weight: 200;
}
.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-1 {
  font-weight: 900;
}
//===background===
.bg-cl-dark {
  background-color: $bg-dark;
}
.bg-cl-light {
  background-color: $bg-light;
}
//===padding===
.pd-t-b-6 {
  padding: 6rem 0rem;
}
@media screen and(max-width:1280px) {
  //text-main
  // .txt-main-1 {
  //   font-size: 120px;
  // }
  // .txt-main-2 {
  //   font-size: 100px;
  // }
  // .txt-main-3 {
  //   font-size: 80px;
  // }
  // .txt-main-4 {
  //   font-size: 60px;
  // }
  // .txt-main-5 {
  //   font-size: 38px;
  // }
  // .txt-main-6 {
  //   font-size: 20px;
  // }
}
@media screen and(max-width:960px) {
  //text-main
  .txt-main-1 {
    font-size: 102px;
  }
  .txt-main-2 {
    font-size: 85px;
  }
  .txt-main-3 {
    font-size: 68px;
  }
  .txt-main-4 {
    font-size: 51px;
  }
  .txt-main-5 {
    font-size: 34px;
  }
  .txt-main-6 {
    font-size: 17px;
  }
}
@media screen and(max-width:600px) {
  //text-main
  .txt-main-1 {
    font-size: 90px;
  }
  .txt-main-2 {
    font-size: 75px;
  }
  .txt-main-3 {
    font-size: 60px;
  }
  .txt-main-4 {
    font-size: 45px;
  }
  .txt-main-5 {
    font-size: 30px;
  }
  .txt-main-6 {
    font-size: 15px;
  }
}
