.mobile-app {
    body {
        overflow-x: hidden;
    }
    .MuiTypography-body1 {
        font-weight: 100;
    }
    .icon-app {
        height: 60px;
    }
    .footer {
        top: auto;
        bottom: 0;
        //background-image: linear-gradient(to right, #0076ba, #16e7cf);
        // a {
        //     color: #fff;
        // }
    }
    .footer-fix-layout {
        height: 109px;
    }
    .MuiAppBar-colorPrimary {
        border-bottom-width: 1px;
        &.footer {
            border-top-width: 1px;
        }
    }

    .transaction-home-page {
        .transaction-item {
            width: 25%;
        }
    }

    .wallet-home-page {
        .wallet-item {
            width: 33.3333%;
        }
    }

    .menu-home-page {
        .menu-item {
            width: 25%;
        }
    }

    .home-page {
        margin-top: -1rem;
    }
}
