.client-page {
  @import "./default/reset.scss";
  @import "./default/color.scss";
  @import "./default/default-style.scss";
  // font-size: 10px;
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    font-family: suisse_intl, Arial, Helvetica, sans-serif;
    .homepage-popover {
      .MuiPaper-root {
        background: $bg-dark;
      }
      ul {
        border-radius: 0.5rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
        overflow: hidden;
        li {
          padding: 1rem 2rem;
          font-size: 1.5rem;
          cursor: pointer;
          color: #37c0ca;
          text-transform: capitalize;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
    .MuiDrawer-paper {
      width: 300px;
      height: 100vh;
      background: $bg-dark;
      .MuiList-root {
        .top {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          .btn-signup {
            background: $bg-light;
            color: #fff;
          }
          .btn-login {
            border: 0.1rem solid #37c0ca;
            color: #37c0ca;
          }
        }
        .MuiListItem-root {
          text-transform: uppercase;
          color: #fff;
          opacity: 0.6;
          padding: 10px 20px;
          &:hover {
            background: rgba(255, 255, 255, 0.1);
          }
        }
        // .logo {
        //   height: 100%;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   img {
        //     width: 20rem;
        //   }
        // }
      }
    }
  }
  @media screen and(max-width:960px) {
    .homepage-popover {
      ul {
        li {
          font-size: 1.2rem !important;
        }
      }
    }
    .MuiDrawer-paper {
      .btn-support,
      .btn-lang {
        display: none;
      }
    }
  }
  @media screen and(max-width:600px) {
    .MuiDrawer-paper {
      .btn-support,
      .btn-lang {
        display: flex;
      }
    }
  }
  .MuiContainer-root {
    margin: 0 auto;
  }

  h3.title-page {
    position: relative;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1em;
    &:after {
      content: "";
      position: absolute;
      bottom: -9px;
      left: 50%;
      height: 1px;
      width: 300px;
      background-color: #28aaffa6;
      transform: translateX(-50%);
    }
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      height: 3px;
      width: 50px;
      background-color: #28aaff;
      transform: translateX(-50%);
    }
  }

  .bg-main-page {
    background-color: #e1e1e1;
    padding-top: 75px;
    @media screen and(min-width:600px) {
      padding-top: 80px;
    }
  }
  .bg-content-page {
    background-color: #fff;
  }
  .blog-page {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #656b6f;
  }

  .MuiSvgIcon-colorPrimary,
  .MuiTypography-colorPrimary {
    color: $color-primary !important;
  }

  .MuiButton-containedPrimary {
    background-color: $color-primary !important;
  }
}
