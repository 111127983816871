.dashboard-page {
    .my-profile {
        .profile-header {
            position: relative;
            z-index: 20;
            .avatar {
                transition: all 300ms ease-in-out;
                border-radius: 50%;
                border: 3px solid #dee2e6;
                background-color: #fff;
                max-width: 160px;
            }
        }

        .profile-info {
            margin-top: -20px;
            padding: 30px 15px 15px 15px;
        }

        .profile-setting {
            @media screen and(min-width:960px) {
                margin-top: 140px;
            }
        }
    }
}
