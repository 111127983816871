html.client-page {
  // .media .list-card .card-item a .box-card,
  // .homepage .MuiPaper-root,
  // html.client-page .blog .box-card a img {
  //   border-radius: 15px;
  //   box-shadow: none;
  // }

  .logo {
    width: 180px;
  }
  button {
    outline: unset !important;
  }
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    padding-top: 10px !important;
    padding-bottom: 14px !important;
    background: /* gradient can be an image */ linear-gradient(to right, #0076ba, #16e7cf) left bottom #777 no-repeat;
    background-size: 100% 4px; /* if linear-gradient, we need to resize it */
    background-color: #17222d;
    .nav-item {
      padding: 0 10px;
    }
    .MuiButton-root {
      min-width: 120px !important;
    }
    .btn-login {
      background-color: #fff;
    }
  }
  html.client-page .sidebar {
    padding: 2.5px 0;
  }
  .intro {
    background-color: #fff;
    img {
      height: 90px;
      margin-bottom: 20px;
      @media screen and(min-width:960px) {
        height: 120px;
      }
    }
  }
  .why-mining-club {
    position: relative;
    .content {
      padding: 50px 0;
      @media screen and(min-width:600px) {
        padding: 100px 0;
      }
      position: relative;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4);
      img {
        width: 50px;
      }
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .banner-homepage {
    position: relative;
    background: url("https://miningclub.org/images/banner-img/Pic-background-MiningClub.jpg") no-repeat 100% 100% #000;
    .contents {
      padding-top: 200px;
      padding-bottom: 200px;
      position: relative;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4);
      .logo-banner {
        width: 250px;
      }
      .fil-coin-logo {
        width: 40px;
        @media screen and(min-width:600px) {
          width: 60px;
        }
      }
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .features {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    .content {
      background: rgba(0, 0, 0, 0.5);
      padding: 50px 0;
      @media screen and(min-width:600px) {
        padding-bottom: 100px;
        padding-top: 0;
      }
    }
    img {
      width: 150px;
      @media screen and(min-width:600px) {
        width: 180px;
      }
    }
    .features-video {
      .content-video {
        display: inline-block;
        border: solid 1px transparent;
        position: relative;
        background-clip: padding-box;
        width: 100%;
        @media screen and(min-width:600px) {
          border: solid 2px transparent;
          width: initial;
        }
        &:before {
          content: "";
          position: absolute;
          z-index: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: -1px;
          border-radius: inherit;
          background: linear-gradient(to right, #0076ba, #16e7cf) left bottom #777 no-repeat;
          @media screen and(min-width:600px) {
            margin: -2px;
          }
        }
        iframe {
          width: 100%;
          height: 200px;
          position: relative;
          z-index: 1;
          display: block;
          @media screen and(min-width:600px) {
            width: 500px;
            height: 280px;
          }
          @media screen and(min-width:960px) {
            width: 750px;
            height: 422px;
          }
        }
      }
    }
  }
  .tutorial {
    background-image: linear-gradient(to right, #0076ba, #16e7cf);
    padding: 50px 0;
    @media screen and(min-width:600px) {
      padding: 100px 0;
    }
    .card {
      height: 100%;
      * {
        color: #000;
      }
    }
    img {
      height: 100px;
      object-fit: contain;
    }
  }
  .blog {
    background-color: #fff;
  }
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    // padding: 5px 0!important;
    .logo img {
      height: 50px;
      width: auto !important;
    }
  }
  @media screen and(max-width:600px) {
    .banner-homepage {
      .contents {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      .MuiTypography-h3 {
        font-size: 1em;
      }
      .MuiTypography-h6 {
        font-size: 0.9em;
      }
    }
  }
}
